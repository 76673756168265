@font-face {
    font-family: coolvetica;
    src: url("/src/fonts/coolvetica/coolvetica\ compressed\ hv.otf");
}

@font-face {
    font-family: leorio;
    src: url("/src/fonts/leorio/Leorio.ttf");
}

body {
    font-family: "coolvetica", "leorio", "ROBOTO", Helvetica, Arial, sans-serif;
    background: rgb(53, 23, 30);
    color: #ffffff;
    font-size: 1.75rem;
    letter-spacing: 2px;
}

.text-muted {
    color: #777;
}

.text-primary {
    color: #000000;
}

.text-decoration {
    text-decoration: underline;
}

p {
    font-size: 14px;
    line-height: 1.75;
}

p.large {
    font-size: 16px;
}

a,
a:hover,
a:focus,
a:active,
a.active {
    outline: 0;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover,
a:focus,
a:active,
a.active {
    color: #fff;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    text-transform: uppercase;
    font-family: "leoria";
    font-weight: 700;
}

button {
    border: none;
    background-color: transparent;
}

.display-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled {
    cursor: not-allowed;
}

.img-centered {
    margin: 0 auto;
}

.bg-light-gray {
    background-color: #f7f7f7;
}

.bg-darkest-gray {
    background-color: #222;
}

.display-align-center {
    display: flex;
    align-items: center;
}

.display-justify-center {
    display: flex;
    justify-content: center;
}

.height-100 {
    height: 100px;
}

.text-align-left {
    justify-content: left;
}

.text-align-right {
    justify-content: right;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-8 {
    margin-top: 8rem;
}

.padding-top-0 {
    padding-top: 0;
}

.max-width-300 {
    max-width: 300px;
}

.btn-primary {
    font-family: "leoria";
    border: 2px solid #fff;
    border-radius: 30px;
    text-align: center;
    padding: 1rem 4rem;
    margin-top: 2rem;
    margin: 0 0.5rem;
    background-color: transparent;
    font-weight: 700;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}

.btn-primary:hover {
    background-color: transparent;
    text-decoration: none;
    border: 2px solid #fff;
}

.btn-primary p {
    margin: 0;
    padding-top: 0 !important;
    color: #fff;
    text-align: center;
    padding-right: 0 !important;
}

.title {
    font-size: 3rem;
    line-height: 3rem;
    color: #ffffff;
    font-family: "leoria";
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
}

.description {
    font-family: "leoria";
    font-size: 1.4rem;
    letter-spacing: 3px;
    color: #ffffff;
    padding: 1rem 0;
}

.description.top {
    padding-top: 0;
}

.tooltip {
    position: relative;
    display: inline-block;
    opacity: 1;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: rgba(182, 0, 0, 0.7);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 10%;
    margin-left: -60px;
    opacity: 0;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #b60000 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
    font-size: 12px;
    font-family: "leorio";
}

.nav {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -webkit-box-shadow: 0 5px 5px 0 rgb(0 0 0 / 4%);
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 4%);
    z-index: 999;
    -webkit-animation: sticky 1.2s;
    animation: sticky 1.2s;
    border-bottom: 1px solid #000;
    background: rgb(53, 23, 30);
}

.nav>.nav-header {
    display: inline;
}

.nav>.nav-header>.nav-title {
    display: inline-block;
    font-size: 22px;
    color: #fff;
    padding: 10px 10px 10px 10px;
}

.nav .nav-header .nav-logo {
    width: auto;
    height: 80px;
}

.nav>.nav-btn {
    display: none;
}

.nav>.nav-links {
    display: inline;
    float: right;
    font-size: 30px;
    font-weight: 600;
}

.nav>.nav-links>a {
    display: inline-block;
    padding: 27px 10px;
    text-decoration: none;
}

/* .nav > .nav-links > a:hover {
    text-decoration: underline;
    text-decoration-color: #ffc50c;
    text-decoration-thickness: 4px;
    text-underline-offset: 27px;
  } */

.nav>.nav-links>a>i {
    font-size: 28px;
    color: #fff;
    cursor: pointer;
}

.nav>#nav-check {
    display: none;
}

@media (max-width: 600px) {
    .nav>.nav-btn {
        display: inline-block;
    }

    .nav>.nav-btn>label {
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
    }

    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid #fff;
    }

    .nav>.nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 100px;
        left: 0px;
    }

    .nav>.nav-links>a {
        display: block;
        width: 100%;
        color: #fff;
        padding-left: 3rem;
    }

    .nav>#nav-check:not(:checked)~.nav-links {
        height: 0px;
    }

    .nav>#nav-check:checked~.nav-links {
        height: fit-content;
        overflow-y: auto;
    }

    .nav>.nav-links>a>i {
        color: #fff;
    }

    .nav-links.socials {
        display: none;
    }
}

section {
    padding: 100px 0 0 0;
}

@media (min-width: 768px) {
    section {
        padding: 150px 0 0 0;
    }
}

header {
    background-image: url('/src/images/backgrounds/header.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-color: #ffffff;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    top: 100px;
}

.header-title {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    position: relative;
    text-align: center;
    padding: 0 2em;
    background: rgba(53, 23, 30, 0.5);
}

h1.span {
    font-size: 5vw;
    letter-spacing: 0.3rem;
    font-family: "coolvetica";
    font-weight: 600;
    cursor: pointer;
}

h1.span:hover {
    color: #000000;
    font-weight: 500;
    font-size: 5.1vw;
}

/* 
  @media (min-width: 800px) {
    .header-title h1 {
      font-size: 5vw;
    }
  } */

.loader span {
    text-shadow: 0 0 0 rgba(182, 0, 0, 0.1);
    -webkit-animation: loading 3s ease-in-out infinite alternate;
}

@-webkit-keyframes loading {
    to {
        text-shadow: 20px 0 70px #ffffff;
        color: #ffffff;
    }
}

.loader span:nth-child(2) {
    -webkit-animation-delay: 0.1s;
}

.loader span:nth-child(3) {
    -webkit-animation-delay: 0.2s;
}

.loader span:nth-child(4) {
    -webkit-animation-delay: 0.3s;
}

.loader span:nth-child(5) {
    -webkit-animation-delay: 0.4s;
}

.loader span:nth-child(6) {
    -webkit-animation-delay: 0.5s;
}

.loader span:nth-child(7) {
    -webkit-animation-delay: 0.6s;
}

.loader span:nth-child(8) {
    -webkit-animation-delay: 0.7s;
}

.loader span:nth-child(9) {
    -webkit-animation-delay: 0.8s;
}

.loader span:nth-child(10) {
    -webkit-animation-delay: 0.9s;
}

.loader span:nth-child(11) {
    -webkit-animation-delay: 1s;
}

.loader span:nth-child(12) {
    -webkit-animation-delay: 1.1s;
}

.loader span:nth-child(13) {
    -webkit-animation-delay: 1.2s;
}

.loader span:nth-child(14) {
    -webkit-animation-delay: 1.3s;
}

.loader span:nth-child(15) {
    -webkit-animation-delay: 1.4s;
}

.loader span:nth-child(16) {
    -webkit-animation-delay: 1.5s;
}

.loader span:nth-child(17) {
    -webkit-animation-delay: 1.6s;
}

.loader span:nth-child(18) {
    -webkit-animation-delay: 1.7s;
}

.loader span:nth-child(19) {
    -webkit-animation-delay: 1.8s;
}

.loader span:nth-child(20) {
    -webkit-animation-delay: 1.9s;
}

.loader span:nth-child(21) {
    -webkit-animation-delay: 2s;
}

.loader span:nth-child(22) {
    -webkit-animation-delay: 2.1s;
}

#drops .information-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#drops .information-section .information {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#drops .information-section .information .button-section {
    padding-bottom: 4rem;
    display: flex;
    justify-content: space-around;
}

.collection-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes scaleUpDown {

    0%,
    100% {
        transform: scaleY(1) scaleX(1);
    }

    50%,
    90% {
        transform: scaleY(1.1);
    }

    75% {
        transform: scaleY(0.95);
    }

    80% {
        transform: scaleX(0.95);
    }
}

@keyframes shake {

    0%,
    100% {
        transform: skewX(0) scale(1);
    }

    50% {
        transform: skewX(5deg) scale(0.9);
    }
}

@keyframes particleUp {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        top: -100%;
        transform: scale(0.5);
    }
}

@keyframes glow {

    0%,
    100% {
        background-color: #ef5a00;
    }

    50% {
        background-color: #ff7800;
    }
}

.fire {
    margin-left: 2rem;
    position: relative;
    width: 40px;
    height: 40px;
    background-color: transparent;
}

/* .fire.position {
    position: absolute;
    left: 15%;
} */

.fire-center {
    position: absolute;
    height: 100%;
    width: 100%;
    animation: scaleUpDown 3s ease-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-center .main-fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(farthest-corner at 10px 0, #d43300 0%, #ef5a00 95%);
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
}

.fire-center .particle-fire {
    position: absolute;
    top: 60%;
    left: 45%;
    width: 10px;
    height: 10px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-right {
    height: 100%;
    width: 100%;
    position: absolute;
    animation: shake 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-right .main-fire {
    position: absolute;
    top: 15%;
    right: -25%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
}

.fire-right .particle-fire {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-left {
    position: absolute;
    height: 100%;
    width: 100%;
    animation: shake 3s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-left .main-fire {
    position: absolute;
    top: 15%;
    left: -20%;
    width: 80%;
    height: 80%;
    background-color: #ef5a00;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
}

.fire-left .particle-fire {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 10%;
    height: 10%;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 3s infinite ease-out 0;
    animation-fill-mode: both;
}

.fire-bottom .main-fire {
    position: absolute;
    top: 30%;
    left: 20%;
    width: 75%;
    height: 75%;
    background-color: #ff7800;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 100% 40%;
    filter: blur(10px);
    animation: glow 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
}

.fire-text {
    margin-left: 4rem;
    width: 90%;
}

.requirement-l {
    display: flex;
    align-items: center;
}

.card {
    height: 500px;
    border: none;
}

.card:before {
    content: "";
    display: block;
    padding-bottom: 150%;
    width: 100%;
}


.card.two .card-background {
    background-image: url(./images/drops/armytokennft.png);
}




.card.three .card-background {
    background-image: url(./images/drops/CronosMarines.png);
}
.card.one .card-background {
    background-image: url(./images/drops/JackalsReloaded.png);
}

.card.six .card-background {
    background-image: url(./images/drops/Ref.png);
}

.card.seven .card-background {
    background-image: url(./images/drops/Ryoshi.png);
}
.card.five .card-background {
    background-image: url(./images/drops/BurnCroFounder.png);
}

.card.sold.one .card-background {
    background-image: url(./images/drops/lion.png);
}

.card.sold.two .card-background {
    background-image: url(./images/drops/burn.gif);
}

.card.sold.three .card-background {
    background-image: url(./images/drops/BTC_Miner.png);
}

.card.sold.four .card-background {
    background-image: url(./images/drops/coalition.jpeg);
}


.card.sold.five .card-background {
    background-image: url(./images/drops/8bit.gif);
}

.card.sold.six .card-background {
    background-image: url(./images/drops/croarmy.png);
}

.card.sold.seven .card-background {
    background-image: url(./images/drops/crocavemann.gif);
}

.card.sold.eight .card-background {
    background-image: url(./images/drops/FBSMain.gif);
}

.card.sold.nine .card-background {
    background-image: url(./images/drops/cockheadz.png);
}

.card.sold.ten .card-background {
    background-image: url(./images/drops/Colonel.jpg);
}
.card.sold.eleven .card-background {
    background-image: url(./images/drops/Jarheads.png);
}

.card.sold.twelve .card-background {
    background-image: url(./images/drops/BunnyGang.jpg);
}
.card.sold.thirteen .card-background {
    background-image: url(./images/drops/CroTronPanthers2.gif);
}

.card.sold.fourteen .card-background {
    background-image: url(./images/drops/armytokennft.png);
}
.card.sold.fifteen .card-background {
    background-image: url(./images/drops/fbs2023.gif);
}

.card.sold.sixteen .card-background {
    background-image: url(./images/drops/BallsAndBears.gif);
}

.card-background {
    background-size: cover;
    background-position: center;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transform-origin: center;
    transform: scale(1) translateZ(0);
    transition: filter 200ms linear, transform 200ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #ff6600;
}

.card-title {
    margin-bottom: 1rem;
    font-size: 3rem;
    text-align: center;
}

.card-sold {
    margin-bottom: 1rem;
    font-size: 2rem;
    text-align: center;
}

.card-circle {
    height: 75px;
    width: 75px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 100%;
    margin-top: 1rem;
}

.card:hover {
    filter: unset;
}

.card:hover .card-background {
    transform: scale(1.05) translateZ(0);
    cursor: pointer;
}

.drops-section:hover .card:not(:hover) .card-background {
    filter: unset;
    filter: brightness(0.5) saturate(1) contrast(1.2) blur(4px);
    cursor: pointer;
}

#roadmap .roadmap-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#roadmap .title {
    padding-bottom: 75px;
}

#roadmap .card:before {
    padding-bottom: 0;
}

#roadmap .card-background {
    border-radius: unset;
    position: inherit;
}

#roadmap .card:hover .card-background {
    transform: scale(1) translateZ(0);
    cursor: default;
}

#roadmap .card .card-background .map-image {
    width: 100%;
    position: relative;
}

#roadmap .card .card-background .icon {
    position: absolute;
    top: 21%;
    left: 17%;
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

#roadmap .card .card-background .icon:nth-child(1) {
    top: 21%;
    left: 17%;
}

#roadmap .card .card-background .icon:nth-child(2) {
    top: 18%;
    left: 93%;
}

#roadmap .card .card-background .icon:nth-child(3) {
    top: 35%;
    left: 74%;
}

#roadmap .card .card-background .icon:nth-child(4) {
    top: 42%;
    left: 96%;
}

#roadmap .card .card-background .icon:nth-child(5) {
    top: 58%;
    left: 64%;
}

#roadmap .card .card-background .icon:nth-child(6) {
    top: 75%;
    left: 89%;
}

#roadmap .card .card-background .icon:nth-child(7) {
    top: 77%;
    left: 15%;
}

#roadmap .card .card-background .icon:nth-child(8) {
    top: 43%;
    left: 31%;
}

#roadmap .card .card-background .icon:hover {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    cursor: pointer;
}

#roadmap .card .card-background .icon .icon-image {
    position: relative;
    width: 50px;
    height: auto;
}

#roadmap .card .card-background .icon .icon-number {
    position: absolute;
    color: #000000;
    font-weight: 700;
    top: 25%;
    left: 43%;
}

#roadmap .roadmap-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 37.5px;
}

#roadmap .roadmap-t {
    display: flex;
}

#roadmap .roadmap-t .number {
    color: #fff;
    background-color: #b60000;
    height: 40px;
    width: auto;
    padding: 1rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2rem;
}

#roadmap .roadmap-t .text {
    text-align: center;
}

#markets .title {
    padding-bottom: 75px;
}

#drops a,
#markets a {
    display: flex;
    justify-content: center;
    align-items: center;
}

#drops .market {
    margin-bottom: 6rem;
}

.market {
    height: 75px;
    width: auto;
}

#team .team-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#team .title {
    padding-bottom: 75px;
}

#team .team-member {
    text-align: center;
}

#team .team-member.card:before {
    padding-bottom: 105%;
}

#team .team-member .card-background {
    height: 350px;
}

#team .team-member .team-member-name {
    font-size: 36px;
}

#team .team-member .team-member-position {
    font-size: 24px;
    margin-bottom: 1rem;
}

#join .join-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

#join .join-man {
    width: auto;
    height: 800px;
}

ul.social-buttons {
    margin-bottom: 0;
}

ul.social-buttons li a {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    font-size: 20px;
    line-height: 40px;
    outline: 0;
    color: #fff;
    background-color: #000;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    text-decoration: none;
}

ul.social-buttons li a img {
    width: 25px;
    height: 25px;
    top: -3px;
    position: relative;
}

ul.social-buttons li a i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

ul.social-buttons li a:hover,
ul.social-buttons li a:focus,
ul.social-buttons li a:active {
    background-color: #000;
}

footer {
    height: 120px;
    display: flex;
    align-items: center;
    font-size: 14px;
}

footer span.copyright {
    text-transform: uppercase;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
    line-height: 40px;
}

footer span.copyright:hover {
    color: #ffffff;
}

footer .footer-logo {
    height: 100%;
    width: auto;
}

@keyframes slide-in-left {
    from {
        transform: translateX(-100%);
        opacity: 0.25;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-in-right {
    from {
        transform: translateX(100%);
        opacity: 0.25;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.token .button-section {
    display: flex;
}

.token .button-section a {
    width: 265px;
    text-decoration: none;
}

.token .token-image {
    width: 300px;
    height: 300px;
}

.token .token-image img {
    width: 100%;
    height: 100%;
}

.mint-amount {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin: 1rem 0;
}

.mint-amount .number {
    margin: 1rem;
    font-size: 20px;
}

.mint-amount .set-amount {
    border-radius: 100%;
    padding: 0.25rem 0.5rem;
    background: #fff;
}

.mint-picture {
    width: 100%;
    height: auto;
}

.token-picture {
    width: 70%;
    height: auto;
}

.animated-paragraph {
    font-size: 3.75rem;
    text-align: center;
}

.not-animated-paragraph {
    display: none;
}

@media (max-width: 768px) {
    .nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        position: inherit;
    }

    .title {
        font-size: 1.75rem;
        line-height: 2rem;
    }

    .title.top {
        margin-top: 2rem;
    }

    .description {
        font-size: 1.2rem;
        text-align: center;
    }

    .description.top {
        padding-top: 1rem;
    }

    #drops .information-section .information {
        width: 100%;
    }

    #drops .information-section .information .button-section {
        flex-direction: column;
    }

    #drops .information-section .information .button-section a:nth-child(1) {
        margin-bottom: 1rem;
    }

    #roadmap .roadmap-t .number {
        width: auto;
        height: 25px;
        margin-right: 1rem;
    }

    #roadmap .roadmap-t .text {
        text-align: left;
    }

    h1.text {
        font-size: 24px;
    }

    h2.number {
        font-size: 20px;
    }

    #roadmap .card .card-background .icon .icon-image {
        width: 40px;
        height: auto;
    }

    #roadmap .card .card-background .icon {
        top: 17%;
        left: 11%;
    }

    #roadmap .card .card-background .icon:nth-child(2) {
        top: 11%;
        left: 90%;
    }

    #roadmap .card .card-background .icon:nth-child(3) {
        top: 32%;
        left: 68%;
    }

    #roadmap .card .card-background .icon:nth-child(4) {
        top: 34%;
        left: 92%;
    }

    #roadmap .card .card-background .icon:nth-child(5) {
        top: 51%;
        left: 62%;
    }

    #roadmap .card .card-background .icon:nth-child(6) {
        top: 73%;
        left: 88%;
    }

    #roadmap .card .card-background .icon:nth-child(7) {
        top: 71%;
        left: 13%;
    }

    #roadmap .card .card-background .icon:nth-child(8) {
        top: 37%;
        left: 29%;
    }

    .align-center-mobile {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    header {
        height: 50vh;
    }

    .header-title {
        font-size: 14px;
    }

    h1.span {
        font-size: 10vw;
    }

    #join .join-man {
        height: 500px;
    }

    #team .team-member .card-background {
        height: 400px;
    }

    .team-member.mobile {
        margin-top: 4rem;
    }

    .market {
        margin-bottom: 2rem;
    }

    #drops .market {
        margin-bottom: 1rem;
    }

    #drops .market.second {
        margin-bottom: 6rem;
    }

    .token .button-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .token-image {
        margin-top: 1rem;
    }

    .buy-now,
    .join-man {
        margin-bottom: 1rem;
    }

    .copyright {
        display: none;
    }

    .mint {
        height: auto;
    }

    .not-animated-paragraph {
        display: block;
        padding: 1rem;
        text-align: center;
        font-size: 36px;
    }

    .animated-paragraph {
        display: none;
    }

    .button-section-center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .join-man {
        width: 100%;
    }
    .fire-text {
        margin-left: 4.5rem;
    }
}

.connected-account {
    display: flex;
}

.connected-account .account {
    padding-left: 1rem;
    color: #5cb85c;
}

.connected-account .no-account {
    padding-left: 1rem;
    color: #d9534f;
}

.no-mint {
    color: #d9534f;
}

.warning {
    color: #d9534f;
}

.big {
    font-size: 32px;
}

.links {
    display: flex;
    margin: 1rem 0;
}

.links a {
    font-size: 36px;
}

.links a:nth-child(2) {
    margin-left: 1rem;
    margin-right: 1rem;
}